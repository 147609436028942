import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";
// import logger from "redux-logger";
import rootReducer from "./reducers/rootReducer";

// Middleware to catch state changes
const stateChangeMiddleware = store => next => action => {
	const result = next(action); // Dispatch the action
	// console.log('State after dispatch:', store.getState()); // Log the state after dispatch
	// You can perform any other side effects here
	return result; // Return the result of next(action)
};

const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunk), applyMiddleware(stateChangeMiddleware))
);

export default store;
