import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import { useNavigate } from 'react-router-dom';
import { getSingle_User } from '../../../../../redux/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API } from '../../../../../backend';
import { Chip } from '@mui/joy';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useEffect } from 'react';

export default function DesignCard({ t, id, currentVarient }) {
  const navigate = useNavigate()
  const [projectCtrator, setProjectCreator] = React.useState(null)
  const getState = useSelector((state) => state);
 
  /// checking price of TICKET
  // const [ticket, setTicket] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios?.get(`${API}diy/gettemplates`);
  //       setTicket(response.data)
  //     } catch (error) {
  //       console.error('error fetching ticket data !!');
  //     }
  //   }
  //   fetchData();
  // }, [])
  // console.log("🚀 ~ DesignCard ~ ticket:", ticket);
  // console.log("🚀 ~ DesignCard ~ ticket Price:", ticket?.price);

  const dispatch = useDispatch()
  // const xsrfToken = getState.xsrfToken?.csrf_token

  const handleNavigate = () => {
    navigate(`/diy/${t?._id}`)
    dispatch({
      type: "ONLYABLE_TO_SEE"
    })
  }

  React.useEffect(() => {
    axios.get(`${API}auth/getsingleUser/${t?.adminId}`)
      .then(({ data }) => setProjectCreator(data))
  }, [t])

  return (
    <Card className=' relative transition-all duration-200 hover:translate-y-[-4px] hover:bg-indigo-50 cursor-pointer' sx={{ width: '100%' }}>
      {
        !t?.price ?
          <Chip color='success' variant='solid' sx={{ position: "absolute", right: 0, top: 0, m: 1 }} >
            Download Ready
          </Chip> :
          <Chip color='danger' variant='solid' sx={{ position: "absolute", right: 0, top: 0, m: 1 }} >
            Print Order Ready
          </Chip>
      }
      <div className='mt-3'>
        <Typography level="title-lg">{t?.title}</Typography>
        <div className=' flex gap-1 items-center'>
          <Typography level="body-sm">{dayjs(t?.created_at).format("DD,MMM,YYYY")}</Typography>
          {currentVarient === "Team Projects" && <span className=' flex items-center mt-[-2px]'>|</span>}
          {currentVarient === "Team Projects" && <span className=' text-sm text-zinc-600'>{projectCtrator?.name} </span>}
        </div>
      </div>
      <AspectRatio minHeight="120px" maxHeight="200px">
        <img src={t?.imgUrl} srcSet={t?.imgUrl} loading="lazy" alt="" />
      </AspectRatio>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Total price:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            {/* Rs.{t?.price || 0}  FOR CURRENT I AM GIVING STATIC PRICE */}
            ₹ {t?.price} (INR)
          </Typography>
        </div>
        <Button onClick={handleNavigate} variant="solid" size="md" color="primary" aria-label="Explore Bahamas Islands" sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }} >
          See Design
        </Button>
      </CardContent>
    </Card>
  );
}