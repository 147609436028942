import React, { useEffect, useState, lazy, Suspense } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Signoutconfirmation from "./Pages/staticPages/Signoutconfirmation";
import Error from "./Pages/staticPages/Error";
import Loading from "./components/commonComponents/LoadingScreen/Loading";
import Review from "./components/commonComponents/popUp/Review";
import Payment from "./components/cartComponents/Payment";
import PdfMaker from "./components/userDashboard/pdf/PdfMaker";
import { useLocation } from "react-router-dom";
import NotSignInPopUp from "./components/commonComponents/Crads/NotSignInPopUp";
import ForgetPassword from "./components/userAuthComp/ForgetPassword";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { Toaster } from "react-hot-toast";
import Main from "./Pages/staticPages/components/Main"
import SingleInvitation from "./Pages/staticPages/components/HeroPages/SingleInvitation";
import ApproverRedirecter from "./components/doityourself/src/components/Pages/ApproverRedirecter";
import MyForms from "./Pages/adminForms/MyForms";
import Chats from "./Pages/chats/Chats";
import ChatContainer from "./Pages/chats/createChat/ChatContainer";
import Mydesigns from "./components/doityourself/src/components/Pages/Mydesigns";
import PrintStore from "./components/doityourself/src/components/Pages/PrintStore";
import { useDispatch, useSelector } from "react-redux";
import { fetchCsrfToken, getUser } from "./redux/actions/userAction";
import Campaign from "./Pages/Campaign";
import Quiz from "./components/doityourself/src/components/Pages/Quiz";
// import StudioInvitation from "./Pages/staticPages/StudioInvitation";
// import SearchedProducts from "./components/commonComponents/SearchedProducts/SearchedProducts";
// import DiyCart from "./components/doityourself/src/components/Pages/DiyCart";
// import Navbar from "./components/commonComponents/Navbar/Navbar";
// import Order from "./components/doityourself/src/components/Pages/Order";
// import LandingPage from "./Pages/LandingPage";
// import AppDiy from "./components/doityourself/src/AppDiy";

export const DesContext = React.createContext({});

const LandingPage = lazy(() => import("./Pages/LandingPage"));
const SignIn = lazy(() => import("./components/userAuthComp/SignIn"));
const Home = lazy(() => import("./Pages/Home"));
const UserAuth = lazy(() => import("./Pages/UserAuth"));
const UserDashboard = lazy(() => import("./components/userDashboard/UserDashboard"));
const ThankYou = lazy(() => import("./Pages/staticPages/ThankYou"));
const PlaceOrder = lazy(() => import("./components/cartComponents/PlaceOrder"));
const AppDiy = lazy(() => import("./components/doityourself/src/AppDiy"));
const Navbar = lazy(() => import("./components/commonComponents/Navbar/Navbar"));
const Order = lazy(() => import("./components/doityourself/src/components/Pages/Order"));
const VerifyOtp = lazy(() => import("./components/userAuthComp/VerifyOtp"));
const UserDiyCheckout = lazy(() => import("../src/components/doityourself/src/components/Pages/Checkout"));
const UserDiyThankyou = lazy(() => import("../src/components/doityourself/src/components/Pages/Thankyou"));
const MockupPreviewPage = lazy(() => import("./components/doityourself/src/components/DoItYourSelf/Canvas/MockupPreviewPage"));
const CreateStudios = lazy(() => import("./Pages/staticPages/CreateStudios"));
const StudioInvitation = lazy(() => import("./Pages/staticPages/StudioInvitation"));
const DiyCart = lazy(() => import("./components/doityourself/src/components/Pages/DiyCart"));
const SearchedProducts = lazy(() => import("./components/commonComponents/SearchedProducts/SearchedProducts"));

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.user.userDetail);
  let navigate = useNavigate();
  let id = "";

  useEffect(() => {
    id = location.pathname.split("/").pop();
    if (id == "null") navigate(-1);
    // id = id[id.length - 1];
    // console.log("id in appjs", id);
  }, [location.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("customDimension")) {
      // alert("app.js");
      localStorage.setItem("customDimension", JSON.stringify({ height: "24", width: "16", uint: "in" }));
    }
    if (!localStorage.getItem("popUp")) {
      // alert("app.js");
      localStorage.setItem("popUp", "enabled");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if ((localStorage.getItem("myuser")) && !userDetails) {
        await dispatch(getUser());
      }
    };
    fetchData();
  }, [dispatch, userDetails]);



  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        {/* {location.pathname.startsWith("/diy") ? null : (<Navbar setIsAdmin={setIsAdmin} />)} */}
        {/* edited by Aqdas */}
        {location.pathname.startsWith("/diy") || location.pathname.startsWith("/orderdiy") || location.pathname.startsWith("/diyhome") ? null : (<Navbar setIsAdmin={setIsAdmin} />)}
        <Toaster />
        <Routes>
          {/* Private Routes */}
          <Route path="/diy/:id" element={<PrivateRoute> <AppDiy setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/printstore" element={<PrivateRoute> <PrintStore setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/campaigns" element={<PrivateRoute> <Campaign setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/mydesigns" element={<PrivateRoute> <Mydesigns setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/quiz" element={<PrivateRoute> <Quiz setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/diyhome" element={<PrivateRoute> <AppDiy setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/myforms" element={<PrivateRoute> <MyForms setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="/chats/*" element={<PrivateRoute> <Chats setIsAdmin={setIsAdmin} /> </PrivateRoute>} />
          <Route path="chat/:id" element={<PrivateRoute> <ChatContainer setIsAdmin={setIsAdmin} /> </PrivateRoute>} />

          <Route path="/diy/mockup-preview" element={<MockupPreviewPage />} />
          <Route path="/orderdiy/:id" element={<Order />} />
          <Route path="/diy/cartpage" element={<DiyCart />} />
          <Route path="/diy/checkout" element={<UserDiyCheckout />} />
          <Route path="/diy/thankyou" element={<UserDiyThankyou />} />
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/null" element={<LandingPage />} /> */}
          <Route path="/cartpage/placeorder" element={<PlaceOrder />} />
          <Route path="/signup" element={<UserAuth />} />
          <Route path="/vrfotp" element={<VerifyOtp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/userdashboard/:id" element={<UserDashboard />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/signout" element={<Signoutconfirmation />} />
          <Route path="*" element={<Error />} />
          <Route path="/invoices" element={<PdfMaker />} />
          <Route path="/review" element={<Review />} />
          <Route path="/pay" element={<Payment />} />
          <Route path="/searchedproduct" element={<SearchedProducts />} />
          <Route path="/wishlistpopup" element={<NotSignInPopUp />} />
          <Route path="/create-studios" element={<CreateStudios />} />
          <Route path="/invitation/studio" element={<StudioInvitation />} />
          <Route path='/studio' element={<PrivateRoute><Main /></PrivateRoute>} />
          <Route path='/singleInvitation/studio' element={<SingleInvitation />} />
          <Route path='/approver_redirect' element={<ApproverRedirecter />} />
        </Routes>
        {/* {location.pathname === `/diy/${id}` || location.pathname === "/diyhome" || location.pathname === `/orderdiy/${id}` ? null : null} */}
      </Suspense>
    </div>
  );
}

export default App;
