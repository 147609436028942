import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

export default function FormSkaliton() {
  return (
    <div className=' p-10 grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-col-1 gap-3 w-full'>

      {
        [...Array(20)]?.map((item, id) => (
          <Box key={id}
            sx={{
              bgcolor: '#d3d9de',
              px: 4,
              py: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: '15px',
              gap: 1
            }}
          >
            <Skeleton variant="rounded" width="100%" height={10} />
            <Skeleton variant="rounded" width='100%' height={20} />
            <Skeleton sx={{ borderRadius: '10px' }} variant="rectangular" width='100%' height={218} />
          </Box>
        ))
      }

    </div>
  );
}