import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DesignCard from '../ownDesigns/DesignCard'
import { API } from '../../../../../backend'
import { Puff } from 'react-loader-spinner'
import CampaignModel from '../ownDesigns/createCampaign/CampaignModel'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export default function PrintStore() {
    const [templates, setTemplates] = useState([]);
    const [designArray, setDesignArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentVarient, setCurrentVarient] = useState('My Projects');
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem("myuser"));
    const userDetails = useSelector(state => state.user.userDetail);
    const varient = ["My Projects", "Team Projects"];

    const myAdminId = JSON.parse(localStorage.getItem("myAdminId"))
    // console.log(myAdminId, "checingmyAdminId");
    // const [materials, setMaterials] = useState([])
    // const [selectedCategories, setSelectedCategories] = useState([])
    // const [sortBytime, setSortBytime] = useState(null)
    // const [selectedmaterial, setSelectedMaterial] = useState(null)
    // const materialsAvailable = [
    //   "Non-Tearable Paper (125-Micron)",
    //   "Premium Self-Adhesive Vinyl",
    //   "Self-Adhesive Vinyl on 3mm Sunboard"
    // ];
    const [campaignData, setCampaignData] = useState({
        user: currentUser?.id || '',
        title: "",
        desc: "",
        startDate: null,
        endDate: null,
        designs: []
    })

    //create campaign
    const createNewCampaign = () => {
        const create = async (getState) => {
            await axios.post(`${API}campaign/createCamp`, campaignData)
                .then(({ data }) => {
                    toast.success("New Campaign is created")
                    navigate('/campaigns')
                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.error('Error:', err);
                })
        }
        //     create()
        // }
        // const [loading, setLoding] = useState(false)
        // const [currentVarient, setCurrentVarient] = useState('My Projects')
        // // useState(async()=>{
        // // await axios.get(`${API}material/getMaterialDiy`)
        // // .then(({data})=>setMaterials(data))
        // // },[])
        // // console.log('allMaterials',materials)
        // useEffect(() => {
        //     const getTemp = async () => {
        //         setLoding(true)
        //         if (currentVarient === "My Projects") {
        //             await axios.get(`${API}diy/gettemplates`)
        //                 .then(({ data }) => {
        //                     const res = data?.filter((t, id) => t?.adminId === userDetails?._id && t?.isApproved)
        //                     setTemplates(res)
        //                     setLoding(false)
        //                 })
        //         }
        //         else if (currentVarient === "Team Projects") {
        //                 // await axios.get(`${API}diy/gettemplates`)
        //                 //     .then(({ data }) => {
        //                 //         const res = data?.filter((f) => (f?.adminId === businessId ||
        //                 //             f?.instruction?.team?.find((t) => t === userDetails?.emailid) && f?.isApproved)
        //                 //         )
        //                 //         console.log("RESPONSE--->", data, res)
        //                 //         setTemplates(res)
        //                 //         setLoding(false)
        //                 //    })
        //                 const {data}=await axios.get(`${API}diy/gettemplates`)
        //                 console.log(data,"all datatemps")
        //               const membertemps= await data.filter((f)=>(f?.adminId===myAdminId && f?.isApproved))
        //               console.log(membertemps,"checingmembertemp")
        //               setTemplates(membertemps)
        //               setLoding(false)
        //         }
    };
    
    // Fetching templates based on current variant
    const fetchTemplates = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${API}diy/gettemplates`);
            // console.log("🚀 ~ fetchTemplates ~ data:", data)   //// commenting for future use
            const filteredTemplates = currentVarient === "My Projects"
                ? data.filter(t => t?.adminId === userDetails?._id && t?.isApproved)
                : data.filter(t => t?.adminId === myAdminId && t?.isApproved);
            setTemplates(filteredTemplates);
        } catch (err) {
            console.error("Error fetching templates:", err);
        } finally {
            setLoading(false);
        }
    };

    // Fetching templates when `currentVarient` or `userDetails` changes
    useEffect(() => {
        fetchTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVarient, userDetails]);

    return (
        <div className='flex gap-2 flex-col px-10 py-3'>
            {/* Filter and Create Campaign Section */}
            <div className='flex gap-2 justify-between flex-wrap items-center'>
                <CampaignModel designArray={designArray} setDesignArray={setDesignArray} createNewCampaign={createNewCampaign} campaignData={campaignData} setCampaignData={setCampaignData} />
                <div className='flex gap-2 items-center'>
                    {
                        varient.map((item) => (
                            <div key={item} className={`cursor-pointer duration-200 transition-all px-3 py-1.5 rounded-md border-[1px] ${currentVarient === item ? 'bg-blue-950 text-white border-indigo-200' : 'bg-white text-black border-indigo-200'}`} onClick={() => setCurrentVarient(item)}>
                                {item}
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* Loading Indicator or Templates */}
            {loading ? (
                <div className='absolute top-[50%] left-[50%] flex justify-center items-center' style={{ transform: 'translate(-50%, -50%)' }}>
                    <Puff visible={true} height="90" width="90" color="#14366e" ariaLabel="puff-loading" />
                </div>
            ) : (
                <div className='grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3'>
                    {
                        templates.map((t, id) => (
                            <DesignCard key={id} currentVarient={currentVarient} t={t} />
                        ))
                    }
                </div>
            )}
        </div>
    );
}
