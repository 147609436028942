const initialState = [];
const initialHistory = {
    past: [],
    present: null,
    future: []
}

const handleHistory = (state = initialState, action) => {
    switch (action.type) {
        case "UNDO": {
            const { past, present, future } = state[action?.payload?.activePage];
            const prev = past[past.length - 1];
            const newPast = past.slice(0, past.length - 1);
            console.log("PAST UNDO AFTER", prev, newPast)
            return {
                ...state,
                [action.payload.activePage]: {
                    past: newPast,
                    present: prev,
                    future: [present, ...future]
                }
            };
        }
        case "REDO": {
            const { past, present, future } = state[action.payload.activePage] || initialHistory;
            if (future.length === 0) return state;

            const next = future[0];
            const newFuture = future.slice(1);

            return {
                ...state,
                [action.payload.activePage]: {
                    past: [...past, present],
                    present: next,
                    future: newFuture
                }
            };
        }

        case "ADD_HISTORY": {
            const { past, present, future } = state[action?.payload?.activePage] || initialHistory;
            // const newPast = present ? [...past, present] : past;
            const newPast = present ? [...past, JSON.parse(JSON.stringify(action.payload.currPage))] : past;
            // console.log("PAST___>", [...past, action.payload.currPage], action.payload.currPage, newPast)
            return {
                ...state,
                [action.payload.activePage]: {
                    // past: present ? [...past, JSON.parse(JSON.stringify(action.payload.currPage))] : [...past,JSON.parse(JSON.stringify(action.payload.currPage))], 
                    past: present ? [...past, JSON.parse(JSON.stringify(present))] : past, // Deep copy of present if we not deep copy like  JSON.parse(JSON.stringify(present) thsi , the all past will be updated as present
                    present: JSON.parse(JSON.stringify(action.payload.currPage)),  // IF we don't clone like this then it will create bug for first two dispatch
                    future: []
                }
            };

        }

        default:
            return state;
    }
}

export default handleHistory;